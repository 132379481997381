import React from 'react';

import { CurrentUser, CurrentUserProvider, QualioQThemeProvider, ToastProvider } from '@qualio/ui-components';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import GlobalProvider from 'providers/GlobalProvider';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';
import { ErrorBoundary } from 'v2views';

import App from './App';
import { theme } from './theme';
import './styles.css';

(window as any).renderSupplierQualityManagement = async (
  containerId: string,
  currentUser: CurrentUser,
  analytics: SegmentAnalytics.AnalyticsJS,
) => {
  // TODO: Resolve hacky way to add datepicker css -- start
  const head = document.getElementsByTagName('HEAD')[0];
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = 'https://cdnjs.cloudflare.com/ajax/libs/react-datepicker/2.14.1/react-datepicker.min.css';
  head.appendChild(link);
  // Resolve hacky way to add datepicker css -- end

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_PK as string,
    user: {
      key: `${currentUser?.companyId}`,
      custom: {
        loggedInUserId: `${currentUser?.userId}`,
        createdTime: `${currentUser.company?.createdTime}`,
        status: `${currentUser.company?.status}`,
      },
    },
    options: {},
  });

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  // Manually detect & add basename when opening new tab
  // until https://qualio.atlassian.net/browse/UX-173 is implemented and the CL is upgraded.
  if (!window.location.pathname.includes('/sqm')) {
    window.history.replaceState('', '', '/sqm' + window.location.pathname);
  }

  ReactDOM.render(
    <ErrorBoundary>
      <CurrentUserProvider user={currentUser}>
        <LDProvider>
          <QueryClientProvider client={queryClient}>
            <GlobalProvider analytics={analytics}>
              <QualioQThemeProvider>
                <ToastProvider>
                  <ThemeProvider theme={theme}>
                    <App />
                  </ThemeProvider>
                </ToastProvider>
              </QualioQThemeProvider>
            </GlobalProvider>
          </QueryClientProvider>
        </LDProvider>
      </CurrentUserProvider>
    </ErrorBoundary>,
    document.getElementById(containerId),
  );
};

(window as any).unmountSupplierQualityManagement = (containerId: string) => {
  try {
    if (document.getElementById(containerId) !== null) {
      ReactDOM.unmountComponentAtNode(document.getElementById(containerId)!);
    }
  } finally {
  }
};

if (process.env.REACT_APP_MOCK) {
  require('./mocks');
}
